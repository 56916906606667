import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal, Spinner } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { listProjects, deleteProject } from './Utils/get';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const ListingContainer = () => {
  const [projects, setProjects] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get('uuid'); 

  const iframeContainerStyle = {
    position: 'relative',
    paddingBottom: '56.38214565387627%',
    height: 0,
  };

  const iframeStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsList = await listProjects(uuid);
      setProjects(projectsList);
      setIsPageLoading(false);
    };

    fetchProjects();
  }, [uuid]);

  const handleDelete = async () => {
    if (projectToDelete) {
      await deleteProject(projectToDelete);
      setProjects(projects.filter(project => project.id !== projectToDelete));
      setShowDeleteModal(false);
      setProjectToDelete(null);
    }
  };

  const confirmDelete = (projectId) => {
    setProjectToDelete(projectId);
    setShowDeleteModal(true);
  };

  return (
    <Container fluid className="p-4">
      {isPageLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="text-center">
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading projects, please wait...</p>
          </div>
        </div>
      ) : (
        <>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <a href='/generator' className="btn ms-auto brand-button-outline d-flex align-items-center">
                <FaPlus className="me-2" />
                New Flashcard
              </a>
            </Col>
          </Row>
          {projects.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <Card className="text-center p-4" style={{ width: '100%'}}>
                  <Card.Body>
                      <div className='col-12 col-md-8 offset-md-2 mb-2'>
                        <div style={iframeContainerStyle}>
                            <iframe
                                title="Flashcard Creation Tutorial"
                                src="https://www.loom.com/embed/9dd89ad1b88e47ce8d2c851e0cc51c74?sid=49340396-1c51-4b36-b3d5-1be8aa47ff8b&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                                frameBorder="0"
                                allowFullScreen
                                style={iframeStyle}
                            ></iframe>
                        </div>
                      </div>
                    <Card.Text>
                      <p>It looks like you don't have any flashcard projects yet.</p>
                      <p>Click the button below to create your first flashcard project!</p>
                    </Card.Text>
                    <a href="/generator" className="btn brand-button-outline">
                      <FaPlus className="me-2" />
                      Create New Flashcard
                    </a>
                  </Card.Body>
                </Card>
              </div>
          ) : (
            <Row>
              {projects.map((project) => (
                <Col key={project.id} xs={12} md={4}>
                  <Card className='mb-3'>
                    <Card.Img style={{objectFit: "contain", maxHeight: "200px"}} variant="top" src={project.coverImage || "https://images.artwand.ai/No-Image-Placeholder.png"} className="card-img-top"/>
                    <Card.Body>
                      <Card.Title>{project.name}</Card.Title>
                      <Card.Text className="text-muted font-small">
                        Created {moment(project.created_at).fromNow()} and updated {moment(project.updated_at).fromNow()}.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                      <Button size="sm" variant="outline-danger" onClick={() => confirmDelete(project.id)} className='me-2'>Delete</Button>
                      <Button size="sm" className="brand-button-outline" onClick={() => window.location.href = `/generator/${project.id}`}>Open</Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this project?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ListingContainer;