// src/GenerateSection.js
import React, { useEffect, useState } from 'react';
import { Button, TextArea, Icon, FormGroup } from '@blueprintjs/core';
import { SectionTab } from 'polotno/side-panel';
import 'shepherd.js/dist/css/shepherd.css';
import Shepherd from 'shepherd.js';

const GeneratePanel = ({ store }) => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageStyle, setImageStyle] = useState('Cartoon'); 

  const startTour = () => {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: {
          enabled: true
        }
      }
    });

    tour.addStep({
      id: 'text-area',
      text: 'This is where you enter the details about your flashcard.',
      attachTo: {
        element: '#prompt-textarea',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'styles-area',
      text: 'Choose the style for your flashcard image here.',
      attachTo: {
        element: '.card-view-container',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'generate-button',
      text: 'Click here to generate your flashcard.',
      attachTo: {
        element: '.brand-button-outline',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });

    tour.start();
  };

  const generate_style_1 = async () => {
    const response = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "action": "generator", prompt, "type": imageStyle }),
    });
    const data = await response.json();
    const imageUrl = data.src;
    const title = data.title;
    const imagePrompt = data.imagePrompt

    const newPage = store.addPage({
      width: 1075,
      height: 1535,
      unit: "px"
    });

    newPage.addElement({
      "type": "svg",
      "name": "background",
      "opacity": 1,
      "visible": true,
      "selectable": true,
      "removable": true,
      "alwaysOnTop": false,
      "showInExport": true,
      "x": 0.02809634034213282,
      "y": 0.7773397674075149,
      "width": 1074.971903659658,
      "height": 1534.2226602325925,
      "rotation": 0,
      "animations": [],
      "blurEnabled": false,
      "blurRadius": 10,
      "brightnessEnabled": false,
      "brightness": 0,
      "sepiaEnabled": false,
      "grayscaleEnabled": false,
      "shadowEnabled": false,
      "shadowBlur": 5,
      "shadowOffsetX": 0,
      "shadowOffsetY": 0,
      "shadowColor": "black",
      "shadowOpacity": 1,
      "draggable": true,
      "resizable": true,
      "contentEditable": true,
      "styleEditable": true,
      "src": "https://images.artwand.ai/card-style-1.svg",
      "maskSrc": "",
      "cropX": 0,
      "cropY": 0,
      "cropWidth": 1,
      "cropHeight": 1,
      "keepRatio": true,
      "flipX": false,
      "flipY": false,
      "borderColor": "black",
      "borderSize": 0,
      "cornerRadius": 0,
      "colorsReplace": {},
      "custom": {}
    });

    newPage.addElement({
      "type": "image",
      "name": "",
      "opacity": 1,
      "visible": true,
      "selectable": true,
      "removable": true,
      "alwaysOnTop": false,
      "showInExport": true,
      "x": 70.07519229307098,
      "y": 298.3621262458414,
      "width": 934.8777117542002,
      "height": 1202.5926928474485,
      "rotation": 0,
      "animations": [],
      "blurEnabled": false,
      "blurRadius": 10,
      "brightnessEnabled": false,
      "brightness": 0,
      "sepiaEnabled": false,
      "grayscaleEnabled": false,
      "shadowEnabled": false,
      "shadowBlur": 5,
      "shadowOffsetX": 0,
      "shadowOffsetY": 0,
      "shadowColor": "black",
      "shadowOpacity": 1,
      "draggable": true,
      "resizable": true,
      "contentEditable": true,
      "styleEditable": true,
      "src": imageUrl,
      "cropX": 0,
      "cropY": 0,
      "cropWidth": 0.9999999999999997,
      "cropHeight": 1,
      "cornerRadius": 0,
      "flipX": false,
      "flipY": false,
      "clipSrc": "data:image/svg+xml;base64,CiAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiPgogICAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtMC45MTk0NzgyODY2MTM0ODEyIj4KICAgICAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiAvPgogICAgPC9jbGlwUGF0aD4KICA8L2RlZnM+CgogIDwhLS0gUGF0aCBmb3IgdGhlIGZpbGwgLS0+CiAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiBmaWxsPSJsaWdodGdyYXkiIHRyYW5zZm9ybT0ic2NhbGUoMSwgMSkiLz4KCiAgPCEtLSBQYXRoIGZvciB0aGUgc3Ryb2tlLCBjbGlwcGVkIGJ5IHRoZSBzdGFyIHBhdGggLS0+CiAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwYzBjMGMiIHN0cm9rZS13aWR0aD0iMCIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtMC45MTk0NzgyODY2MTM0ODEyKSIgdHJhbnNmb3JtPSJzY2FsZSgxLCAxKSIgc3Ryb2tlLWRhc2hhcnJheT0iIi8+CiAgICA8L3N2Zz4KICA=",
      "borderColor": "black",
      "borderSize": 0,
      "keepRatio": false,
      "custom": {"prompt": imagePrompt}
    });

    newPage.addElement({
      "type": "text",
      "name": "title",
      "opacity": 1,
      "visible": true,
      "selectable": true,
      "removable": true,
      "alwaysOnTop": false,
      "showInExport": true,
      "x": 70.07519229306135,
      "y": 114.99003322258643,
      "width": 935,
      "height": 167,
      "rotation": 0,
      "animations": [],
      "blurEnabled": false,
      "blurRadius": 10,
      "brightnessEnabled": false,
      "brightness": 0,
      "sepiaEnabled": false,
      "grayscaleEnabled": false,
      "shadowEnabled": false,
      "shadowBlur": 5,
      "shadowOffsetX": 0,
      "shadowOffsetY": 0,
      "shadowColor": "black",
      "shadowOpacity": 1,
      "draggable": true,
      "resizable": true,
      "contentEditable": true,
      "styleEditable": true,
      "text": title,
      "placeholder": "",
      "fontSize": 138,
      "fontFamily": "Inter",
      "fontStyle": "normal",
      "fontWeight": "normal",
      "textDecoration": "",
      "fill": "rgba(255,255,255,1)",
      "align": "center",
      "verticalAlign": "top",
      "strokeWidth": 0,
      "stroke": "black",
      "lineHeight": 1.2,
      "letterSpacing": 0,
      "backgroundEnabled": false,
      "backgroundColor": "#7ED321",
      "backgroundOpacity": 1,
      "backgroundCornerRadius": 0.5,
      "backgroundPadding": 0.5,
      "custom": {}
    });

    setLoading(false);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      generate_style_1();
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  useEffect(() => {
    const onboardingKey = "onboarding:editor";
    if (!localStorage.getItem(onboardingKey)) {
      startTour();
      localStorage.setItem(onboardingKey, "true");
    }
  }, []);


  return (
    <div style={{ padding: '16px' }}>
      <div className='text-dark mb-3'><strong>Single Flashcard Generator</strong></div>
      <hr />
      <FormGroup
        label={
            <span className="font-small ps-1">Tell me about your flashcard</span>
          }
        labelFor="prompt-textarea"
        className="font-small"
      >
        <TextArea
          id="prompt-textarea"
          fill
          growVertically
          large
          placeholder="A flashcard for children. The card title is Earth with a cute Earth image."
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className='font-small'
          style={{ borderRadius: '10px' }}
        />
      </FormGroup>

      <FormGroup
        label={<span className="font-small ps-1">Image Style</span>}
        className="font-small"
      >
        <div className="card-view-container" style={{ display: 'flex', gap: '8px' }}>
          <Button
            className={`d-flex align-items-center font-small card-view ${imageStyle === 'Cartoon' ? 'selected' : ''}`}
            onClick={() => setImageStyle('Cartoon')}
            style={{ gap: '8px' }}
          >
            <img src="https://images.artwand.ai/flashcard-cartoon-style.jpg" alt="Cartoon" style={{ maxWidth: '100%' }} />
            <span className='d-flex align-items-center justify-content-center pt-1'>Cartoon</span>
          </Button>
          <Button
            className={`d-flex align-items-center font-small card-view ${imageStyle === 'Illustration' ? 'selected' : ''}`}
            onClick={() => setImageStyle('Illustration')}
            style={{ gap: '8px' }}
          >
            <img src="https://images.artwand.ai/flashcard-illustration-style.jpg" alt="Illustration" style={{ maxWidth: '100%' }} />
            <span className='d-flex align-items-center justify-content-center pt-1'>Illustration</span>
          </Button>
          <Button
            className={`d-flex align-items-center font-small card-view ${imageStyle === 'Realistic' ? 'selected' : ''}`}
            onClick={() => setImageStyle('Realistic')}
            style={{ gap: '8px' }}
          >
            <img src="https://images.artwand.ai/football-ball.jpg" alt="Realistic" style={{ maxWidth: '100%' }} />
            <span className='d-flex align-items-center justify-content-center pt-1'>Realistic</span>
          </Button>
        </div>
      </FormGroup>

      <Button
        text="Generate"
        className='brand-button-outline'
        onClick={handleGenerate}
        loading={loading}
        disabled={prompt ? false : true}
        style={{ marginTop: '16px', borderRadius: '9999px' }}
      />
    </div>
  );
};

export const GenerateSection = {
  name: 'generate',
  Tab: (props) => (
    <SectionTab name="Single" {...props}>
      <Icon icon="stop" />
    </SectionTab>
  ),
  Panel: GeneratePanel,
};