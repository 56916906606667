import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { FiSend } from "react-icons/fi";
import HeaderMenu from './Header';

function StartDesign() {
  const [rows, setRows] = useState([{ id: Date.now(), value: '' }]);

  const handleAddRow = () => {
    setRows([...rows, { id: Date.now(), value: '' }]);
  };

  const handleDeleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleInputChange = (id, event) => {
    const newValue = event.target.value;
    setRows(
      rows.map((row) => (row.id === id ? { ...row, value: newValue } : row))
    );
  };

  return (
    <div>
    <HeaderMenu />
    <div className="d-flex">
      <div className="flex-grow-1">
      <Container className="mt-4">
        <h4>Directions</h4>
        <p>You can add flashcard content in the row below. Each row represents a new flashcard, and you can add as many rows as you like.</p>
      {rows.map((row, index) => (
        <Row key={row.id} className="align-items-center mb-2">
          <Col xs="auto" className='pe-0'>
            {index === rows.length - 1 ? (
              <Button variant="success" onClick={handleAddRow}>
                <FaPlus />
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={() => handleDeleteRow(row.id)}
              >
                <FaTrash />
              </Button>
            )}
          </Col>
          <Col className='ps-1'>
            <Form.Control
              type="text"
              placeholder="Write your flashcard content."
              value={row.value}
              onChange={(e) => handleInputChange(row.id, e)}
            />
          </Col>
        </Row>
      ))}
       <Row className="mt-3">
        <Col className="text-end d-flex justify-content-end">
          <Button disabled={rows.length === 1} className='brand-button-outline d-flex align-items-center'><FiSend className='me-1' />Generate</Button>
        </Col>
      </Row>
    </Container>
      </div>
    </div>
  </div>
);
}

export default StartDesign;