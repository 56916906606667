import { database } from '../firebase';
import { getDatabase, ref, get, set, update, query, orderByChild, equalTo } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const checkUserExists = async (userId) => {
  const database = getDatabase();
  const userRef = ref(database, `users/${userId}`);

  try {
    const snapshot = await get(userRef);
    return snapshot.exists();
  } catch (error) {
    console.error('Error checking if user exists:', error);
    return false;
  }
};

export const createAccount = async () => {
  const database = getDatabase();
  const accountId = uuidv4();
  const accountRef = ref(database, `accounts/${accountId}`);
  const newAccount = {
    id: accountId,
    name: "New Account",
    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
  };

  try {
    await set(accountRef, newAccount);
    return accountId;
  } catch (error) {
    console.error('Error creating account:', error);
    throw error;
  }
};

export const createUser = async (userId, displayName, email, accountId) => {
  const database = getDatabase();
  const userRef = ref(database, `users/${userId}`);
  const newUser = {
    id: userId,
    displayName: displayName,
    email: email,
    accounts: [{ accountId: accountId, role: 'admin' }]
  };

  try {
    await set(userRef, newUser);
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const createProject = async (formData) => {
  const formId = uuidv4();
  const formRef = ref(database, `projects/${formId}`);
  const newForm = {
    id: formId,
    ...formData,
    accountId: await getAccount(),
    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().format('YYYY-MM-DD HH:mm:ss')
  };

  try {
    await set(formRef, newForm);
    return formId;
  } catch (error) {
    console.error('Error creating form:', error);
    throw error;
  }
};

export const getAccount = async () => {
  const userId = JSON.parse(localStorage.getItem('S:User')).uid;
  const userRef = ref(database, `users/${userId}`);

  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const userData = snapshot.val();
      if (userData.accounts && userData.accounts.length > 0) {
        return userData.accounts[0].accountId;
      } else {
        throw new Error('No accounts found for this user.');
      }
    } else {
      throw new Error('User not found.');
    }
  } catch (error) {
    console.error('Error getting account ID:', error);
    throw error;
  }
};

export const incrementAccountUsage = async (accountId) => {
  const db = getDatabase();
  const accountRef = ref(db, `accounts/${accountId}`);

  try {
    const snapshot = await get(accountRef);
    if (snapshot.exists()) {
      const accountData = snapshot.val();
      const currentUsage = accountData.usage || 0; // Default to 0 if usage is undefined

      // Increment the usage by 1
      const updatedUsage = currentUsage + 1;

      // Update the account with the new usage value
      await update(accountRef, { usage: updatedUsage });

      console.log(`Usage for account ${accountId} increased to ${updatedUsage}.`);
      return updatedUsage;
    } else {
      throw new Error('Account not found.');
    }
  } catch (error) {
    console.error('Error updating account usage:', error);
    throw error;
  }
};

export const getProject = async (projectId) => {
  const formRef = ref(database, `projects/${projectId}`);

  try {
    const snapshot = await get(formRef);
    if (snapshot.exists()) {
      const formData = snapshot.val();

      return formData;
    } else {
      throw new Error('Form not found.');
    }
  } catch (error) {
    console.error('Error getting form:', error);
    throw error;
  }
};

export const updateProjectDesign = async (projectId, generatedCards) => {
  const formRef = ref(database, `projects/${projectId}`);

  // Remove undefined values from dataJson
  const sanitizedDataJson = removeUndefined(generatedCards);

  try {
    await update(formRef, { generatedCards: sanitizedDataJson });
    console.log('Design updated successfully.');
  } catch (error) {
    console.error('Error updating form:', error);
    throw error;
  }
};

const removeUndefined = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefined);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v !== undefined)
        .map(([k, v]) => [k, removeUndefined(v)])
    );
  }
  return obj;
};

export const listProjects = async (selectedAccountId) => {
  let accountId = await getAccount();

  if (selectedAccountId) {
    accountId = selectedAccountId;
  }

  if (!accountId) {
    throw new Error('No account ID found in local storage.');
  }

  const db = getDatabase();
  const formsRef = ref(db, 'projects');
  const formsQuery = query(formsRef, orderByChild('accountId'), equalTo(accountId));

  try {
    const snapshot = await get(formsQuery);

    if (snapshot.exists()) {
      const formsData = snapshot.val();

      // Filter out forms where isDeleted is set to true
      const filteredForms = Object.keys(formsData)
      
        .map(key => ({ id: key, ...formsData[key] }))
        .filter(form => form.isDeleted !== true); // Filter out forms with isDeleted: true

      // Sort forms by create_at descending order
      const sortedForms = filteredForms.sort((a, b) => 
        new Date(b.created_at) - new Date(a.created_at)
      );

      return sortedForms;
    } else {
      console.log('No forms found for this account.');
      return [];
    }
  } catch (error) {
    console.error('Error fetching forms:', error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  const projectRef = ref(database, `projects/${projectId}`);

  try {
    await update(projectRef, { isDeleted: true });
    console.log('Project marked as deleted successfully.');
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};

export const getUser = async () => {
  const userId = JSON.parse(localStorage.getItem('S:User')).uid;
  const userRef = ref(database, `users/${userId}`);

  try {
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const userData = snapshot.val();

      return userData
    } else {
      throw new Error('User not found.');
    }
  } catch (error) {
    console.error('Error fetching user name and surname:', error);
    throw error;
  }
};

export const getAccountData = async (accountId) => {
  const accountRef = ref(database, `accounts/${accountId}`);

  try {
    const snapshot = await get(accountRef);

    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      throw new Error('User not found.');
    }
  } catch (error) {
    console.error('Error fetching user name and surname:', error);
    throw error;
  }
};