import React, { useEffect, useState } from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Button, Navbar } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Workspace } from 'polotno/canvas/workspace';
import { SidePanel, UploadSection, LayersSection, TextSection, ElementsSection } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { createStore } from 'polotno/model/store';
import { UploadPanel } from './Utils/UploadPanel';
import { getProject, updateProjectDesign } from './Utils/get';
import { useParams } from 'react-router-dom';
import { Spinner, Modal } from 'react-bootstrap';
import PackageList from './PackageList';

const store = createStore({
  key: process.env.REACT_APP_POLOTNO_API_KEY,
  showCredit: false,
});

UploadSection.Panel = UploadPanel;

const Edit = () => {
  const { slug, cardId } = useParams();
  const [designSaving, setDesignSaving] = useState(false);
  const [name, setName] = useState("Artwand Flashcard Studio");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);


  useEffect(() => {
    if (slug) {
      getProject(slug).then((response) => {
        setName(response.name);
        const dataJson = response.generatedCards;
        const selectedCard = dataJson.find(card => card.id === cardId);
        
        setCards(dataJson);

        if (selectedCard) {
          store.loadJSON(selectedCard.dataJson);
        }
        setIsPageLoading(false);
      });
    } else {
      store.setSize(1075, 1535);
    }
  }, [slug, cardId]);

  const handleClose = async () => {
    await handleSave();
    window.location.href = `/generator/${slug}`;
  };

  const handleSave = async () => {
    setDesignSaving(true);
    
    // Convert store to JSON
    const dataJson = store.toJSON();

    const updatedCards = cards.map(card => 
      card.id === cardId ? { ...card, dataJson } : card
    );

    // Save to Firebase
    try {
      await updateProjectDesign(slug, updatedCards);
      
    } catch (error) {
      console.error("Error saving design: ", error);
      setDesignSaving(false);
    }
  };

  return (
    <div style={{ height: `${window.innerHeight}px`, display: 'flex', flexDirection: 'column' }}>
      <Navbar className='d-flex align-items-center' style={{ whiteSpace: 'nowrap' }}>
        <Navbar.Group className="d-none d-md-flex align-items-center">
          <Navbar.Heading>{name}</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align="right" style={{flexWrap: 'nowrap' }} className='d-flex justify-content-center ms-auto'>
          <Button variant="outline-dark" size="sm" icon="cross" text="Close" onClick={handleClose} className='m-1' disabled={designSaving} loading={designSaving}/>
        </Navbar.Group>
      </Navbar>
      {isPageLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="text-center">
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading flashcards, please wait...</p>
          </div>
        </div>
      ) : (
        <PolotnoContainer style={{ flex: 1, width: '100vw' }}>
          <SidePanelWrap>
            <SidePanel store={store} sections={[LayersSection, TextSection, ElementsSection, UploadSection]} defaultSection="text"  />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} />
            <Workspace store={store}/>
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
        )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe to Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PackageList />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Edit;