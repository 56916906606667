import React from 'react';
import HeaderMenu from './Header';
import PackageList from './PackageList';

const Subscriptions = () => (
  <div>
    <HeaderMenu />
    <PackageList />
  </div>
);



export default Subscriptions;