// src/PlanOffer.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBolt, FaUnlock, FaPaintBrush, FaDollarSign } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const PlanOffer = () => {
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState('lifetime');
    const email = JSON.parse(localStorage.getItem('S:User')).email

    const handleClose = () => {
        navigate(-1);
    };

    const handlePlanChange = (event) => {
        setSelectedPlan(event.target.id);
    };

    const getPlanLink = () => {
        const encodedEmail = encodeURIComponent(email);
        switch (selectedPlan) {
            case 'monthly':
                return 'https://buy.stripe.com/7sI29Z7kd5mF7aEdRe?prefilled_email=' + encodedEmail;
            case 'lifetime':
                return 'https://buy.stripe.com/5kA29ZbAt7uNcuY4gG?prefilled_email=' + encodedEmail;
            case 'annual':
            default:
                return 'https://buy.stripe.com/28ocOD6g9eXfgLe00p?prefilled_email=' + encodedEmail;
        }
    };

    const iconStyle = {
        backgroundColor: '#e9ecef',
        borderRadius: '50%',
        padding: '10px',
        fontSize: '1rem',
        marginRight: '10px'
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100" style={{ background: 'linear-gradient(to right, #f8f9fa, #e9ecef)' }}>
            <div className="container p-4" style={{ maxWidth: '900px', background: '#fff', borderRadius: '15px', position: 'relative' }}>
                <button className="btn-close position-absolute top-0 end-0 m-3" aria-label="Close" onClick={handleClose}></button>
                <h2 className="text-center my-4 mb-5">Unlock Unlimited Flashcard <span className="text-primary">with Pro</span></h2>
                <div className="row">
                    <div className="col-md-6 d-none d-md-flex justify-content-center">
                        <img src="https://images.artwand.ai/flascards-cover.png" alt="Creative" className="img-thumbnail" />
                    </div>
                    <div className="col-md-6">
                        <ul className="list-unstyled" style={{ fontSize: '1.1rem' }}>
                            <li className="mb-4"><span style={iconStyle}><FaBolt /></span> Unlimited Flashcard Generation</li>
                            <li className="mb-4"><span style={iconStyle}><FaUnlock /></span> Unlimited Export as Image or PDF</li>
                            <li className="mb-4"><span style={iconStyle}><FaPaintBrush /></span> Unlimited Image Generation with AI</li>
                            <li className="mb-4"><span style={iconStyle}><FaDollarSign /></span> Use Commercially</li>
                        </ul>
                        <div className="mb-4">
                            <div className="d-flex">
                                <div className="d-none form-check p-3 mb-2 me-2 flex-fill position-relative" style={{ border: '2px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
                                    <input className="form-check-input position-absolute top-0 end-0 m-2" type="radio" name="plan" id="annual" onChange={handlePlanChange} />
                                    <label className="form-check-label d-flex flex-column" htmlFor="annual">
                                        <span className="fw-bold mb-2">Annual <span className="badge bg-primary">-65%</span></span>
                                        <span>$4 / month</span><span className='font-small mt-1'> ($48 / year)</span>
                                    </label>
                                </div>
                                <div className="d-none form-check p-3 mb-2 flex-fill position-relative" style={{ border: '2px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
                                    <input className="form-check-input position-absolute top-0 end-0 m-2" type="radio" name="plan" id="monthly" onChange={handlePlanChange} />
                                    <label className="form-check-label d-flex flex-column" htmlFor="monthly">
                                        <span className="fw-bold mb-2">Monthly</span>
                                        <span>$9 / month</span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-check p-3 position-relative" style={{ border: '2px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
                                <input className="form-check-input position-absolute top-0 end-0 m-2" type="radio" name="plan" id="lifetime" onChange={handlePlanChange} defaultChecked />
                                <label className="form-check-label d-flex flex-column" htmlFor="lifetime">
                                    <span className="fw-bold mb-2">Lifetime Access</span>
                                    <span>$49 one-time purchase</span>
                                </label>
                            </div>
                        </div>
                        <a href={getPlanLink()} className="btn btn-dark w-100">Upgrade to Lifetime Access</a>
                        <p className="text-center mt-3 text-muted font-small">By purchasing, you agree to our <a href="https://www.artwand.ai/terms-condifutions" target='_blank' rel="noreferrer">Terms & Conditions and Privacy Policy</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanOffer;