import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { signInWithGoogle } from './firebase';

function Register() {
  return (
    <div className="container-fluid">
      <div className="row">
        {/* Left Side */}
        <div
          className="d-none d-md-block col-md-6 d-flex justify-content-center align-items-center"
          style={{ 
            backgroundColor: '#f8f9fa', 
            height: '100vh',
            backgroundImage: 'url(https://images.artwand.ai/flascards-cover.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          {/* Removed the img tag as the background image is now set */}
        </div>

        {/* Right Side */}
        <div
          className="col-12 col-md-6 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: '#ffffff', height: '100vh' }}
        >
          <div className="d-flex flex-column align-items-center">
            <img src="/images/artwand-logo.svg" alt="ArtWand" className="img-fluid" style={{ width: '50%' }}/>
            <h4 className='pt-0 mt-0'>Unlimited Flashcard Generator</h4>
            <button onClick={signInWithGoogle} className="mt-5 btn brand-button-outline fs-4 d-flex align-items-center mb-3">
              <FcGoogle className='me-2 display-7' />Continue with Google
            </button>
            <span>Trusted by 500+ users around the world!</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;