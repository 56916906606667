import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const getCustomDesign = async (id) => {
  try {
    const response = await axios.post('https://platform.api.plugger.ai/customize/get', {
      file: id
    });
    const transformedData = transformDesignData(response.data.data);
    return transformedData;
  } catch (error) {
    console.error('Error fetching custom design:', error);
    window.location.href = 'https://platform.plugger.ai';
  }
};

const transformDesignData = (data) => {
  const transformedData = {
    ...data,
    pages: data.pages.map(page => ({
      ...page,
      type: page.type === "staticImage" ? "image" : page.type,
      x: Number(page.x),
      y: Number(page.y),
      width: Number(page.width),
      height: Number(page.height),
    }))
  };
  return transformedData;
};

export const UploadDesignImage = async (requestBody) => {
  try {
    const response = await axios.post('https://platform.api.plugger.ai/customize/upload', requestBody);
    return response.data
  } catch (error) {
  }
};

export const UpdateDesignFile = async (requestBody) => {
  try {
    const response = await axios.post('https://platform.api.plugger.ai/customize/update', requestBody);
    return response.data
  } catch (error) {
  }
};

export const UploadFileUrlGet = async (requestBody) => {
  try {
    const response = await axios.post('https://platform.api.plugger.ai/file/generate', requestBody);
    return response.data
  } catch (error) {
  }
};

const handleImageUpload = async (file) => {
  const { name, type } = file;
  const fileUuid = 'image_' + uuidv4();
  
  try {
    const result = await UploadFileUrlGet({
      fileUuid,
      fileName: name,
      fileType: type,
    });

    await fetch(result.url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': type,
      },
    });

    const imageUrl = `${process.env.REACT_APP_S3_URL}/${fileUuid}/${name}`;
    return imageUrl;
  } catch (error) {
    console.error('Image upload error:', error);
    // Handle error (e.g., show an alert to the user)
    return null;
  }
};

const delay = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms));

export async function localFileToURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function getImages() {
  // delay to emulate network request
  await delay();
  return JSON.parse(localStorage.getItem('images') || '[]');
}

export async function saveImage(file) {
  // Upload image to S3
  const imageUrl = await handleImageUpload(file);
  
  if (imageUrl) {
    const images = JSON.parse(localStorage.getItem('images') || '[]');
    images.push({
      id: Date.now().toString(),
      url: imageUrl,
    });
    localStorage.setItem('images', JSON.stringify(images));
    return imageUrl;
  } else {
    throw new Error('Failed to upload image');
  }
}

export async function deleteImage(id) {
  // delay to emulate network request
  await delay();
  const images = JSON.parse(localStorage.getItem('images') || '[]');
  const newImages = images.filter((image) => image.id !== id);
  localStorage.setItem('images', JSON.stringify(newImages));
}