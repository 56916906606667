const PackageList = () => {
    const subscriptionOptionStyle = {
        backgroundColor: '#2c3e50',
        color: 'white',
        borderRadius: '8px',
        overflow: 'hidden',
        width: '300px',
        textAlign: 'center',
      };
      
      const headerStyle = {
        margin: '0',
        padding: '20px',
      };
      
      const priceStyle = {
        backgroundColor: '#34495e',
        padding: '20px',
        fontSize: '24px',
      };
      
      const currencyStyle = {
        fontSize: '18px',
      };
      
      const amountStyle = {
        fontSize: '48px',
      };
      
      const periodStyle = {
        fontSize: '0.75rem',
      };
      
      const featuresStyle = {
        backgroundColor: '#2c3e50',
        padding: '20px',
      };
      
      const buttonStyle = {
        border: 'none',
        color: 'white',
        padding: '15px',
        width: '100%',
        cursor: 'pointer',
        fontSize: '18px',
      };

      const stickerStyle = {
        color: 'black',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
      };

      const stickerStyleWhite = {
        backgroundColor: '#ffffff',
        color: 'black',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
      };

    return (
        <div className="p-3" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <div style={subscriptionOptionStyle}>
                <h2 style={{ ...headerStyle, backgroundColor: '#3498db' }}>Monthly</h2>
                <div style={priceStyle}>
                <div style={stickerStyle}>&nbsp;</div>
                <span style={currencyStyle}>$</span>
                <span style={amountStyle}>5</span>
                <br />
                <span style={periodStyle}>per month</span>
                </div>
                <div style={featuresStyle}>
                <p>Unlimited Flashcards</p>
                <hr />
                <p>Unlimited Export</p>
                </div>
                <a href="https://buy.stripe.com/6oE01R7kd16p52w3ct" className='btn' style={{ ...buttonStyle, backgroundColor: '#3498db' }}>Get Started</a>
            </div>
            <div style={subscriptionOptionStyle}>
                <h2 style={{ ...headerStyle, backgroundColor: '#e74c3c' }}>Annually</h2>
                <div style={priceStyle}>
                <div style={stickerStyleWhite}>60% discounted</div>
                <span style={currencyStyle}>$</span>
                <span style={amountStyle}>2</span>
                <br />
                <span style={periodStyle}>per month</span>
                </div>
                <div style={featuresStyle}>
                <p>Unlimited Flashcards</p>
                <hr />
                <p>Unlimited Export</p>
                </div>
                <a href='https://buy.stripe.com/5kA4i76g93exeD67sK' className="btn" style={{ ...buttonStyle, backgroundColor: '#e74c3c' }}>Get Started</a>

            </div>
        </div>
    )
}

export default PackageList;