import React, { useState } from 'react';
import { Button, TextArea, Icon, FormGroup, Dialog } from '@blueprintjs/core';
import { SectionTab } from 'polotno/side-panel';

const MultipleGeneratePanel = ({ store }) => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageStyle, setImageStyle] = useState('Cartoon');
  const [progress, setProgress] = useState(0);
  const [totalFlashcards, setTotalFlashcards] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const iframeContainerStyle = {
    position: 'relative',
    paddingBottom: '56.38214565387627%',
    height: 0,
  };

  const iframeStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
  };

  const generate_style_1 = async () => {
    const response = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "action": "card-context", prompt }),
    });
    const cardContext = await response.json();
    const flashcards = cardContext.flashcards;
    setTotalFlashcards(flashcards.length);

    for (let i = 0; i < flashcards.length; i++) {
      const flashcard = flashcards[i];
      
      const cardGenerator = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "action": "generator", prompt, "type": imageStyle, data: flashcard }),
      });
      const data = await cardGenerator.json();
      const imageUrl = data.src;
      const title = data.title;
      const imagePrompt = data.imagePrompt

      // Create a new page for each flashcard
      const newPage = store.addPage({
        width: 1075,
        height: 1535,
        unit: "px"
      });

      newPage.addElement({
        "type": "svg",
        "name": "background",
        "opacity": 1,
        "visible": true,
        "selectable": true,
        "removable": true,
        "alwaysOnTop": false,
        "showInExport": true,
        "x": 0.02809634034213282,
        "y": 0.7773397674075149,
        "width": 1074.971903659658,
        "height": 1534.2226602325925,
        "rotation": 0,
        "animations": [],
        "blurEnabled": false,
        "blurRadius": 10,
        "brightnessEnabled": false,
        "brightness": 0,
        "sepiaEnabled": false,
        "grayscaleEnabled": false,
        "shadowEnabled": false,
        "shadowBlur": 5,
        "shadowOffsetX": 0,
        "shadowOffsetY": 0,
        "shadowColor": "black",
        "shadowOpacity": 1,
        "draggable": true,
        "resizable": true,
        "contentEditable": true,
        "styleEditable": true,
        "src": "https://images.artwand.ai/card-style-1.svg",
        "maskSrc": "",
        "cropX": 0,
        "cropY": 0,
        "cropWidth": 1,
        "cropHeight": 1,
        "keepRatio": true,
        "flipX": false,
        "flipY": false,
        "borderColor": "black",
        "borderSize": 0,
        "cornerRadius": 0,
        "colorsReplace": {},
        "custom": {}
      });

      newPage.addElement({
        "type": "image",
        "name": "",
        "opacity": 1,
        "visible": true,
        "selectable": true,
        "removable": true,
        "alwaysOnTop": false,
        "showInExport": true,
        "x": 70.07519229307098,
        "y": 298.3621262458414,
        "width": 934.8777117542002,
        "height": 1202.5926928474485,
        "rotation": 0,
        "animations": [],
        "blurEnabled": false,
        "blurRadius": 10,
        "brightnessEnabled": false,
        "brightness": 0,
        "sepiaEnabled": false,
        "grayscaleEnabled": false,
        "shadowEnabled": false,
        "shadowBlur": 5,
        "shadowOffsetX": 0,
        "shadowOffsetY": 0,
        "shadowColor": "black",
        "shadowOpacity": 1,
        "draggable": true,
        "resizable": true,
        "contentEditable": true,
        "styleEditable": true,
        "src": imageUrl,
        "cropX": 0,
        "cropY": 0,
        "cropWidth": 0.9999999999999997,
        "cropHeight": 1,
        "cornerRadius": 0,
        "flipX": false,
        "flipY": false,
        "clipSrc": "data:image/svg+xml;base64,CiAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiPgogICAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtMC45MTk0NzgyODY2MTM0ODEyIj4KICAgICAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiAvPgogICAgPC9jbGlwUGF0aD4KICA8L2RlZnM+CgogIDwhLS0gUGF0aCBmb3IgdGhlIGZpbGwgLS0+CiAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiBmaWxsPSJsaWdodGdyYXkiIHRyYW5zZm9ybT0ic2NhbGUoMSwgMSkiLz4KCiAgPCEtLSBQYXRoIGZvciB0aGUgc3Ryb2tlLCBjbGlwcGVkIGJ5IHRoZSBzdGFyIHBhdGggLS0+CiAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwYzBjMGMiIHN0cm9rZS13aWR0aD0iMCIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtMC45MTk0NzgyODY2MTM0ODEyKSIgdHJhbnNmb3JtPSJzY2FsZSgxLCAxKSIgc3Ryb2tlLWRhc2hhcnJheT0iIi8+CiAgICA8L3N2Zz4KICA=",
        "borderColor": "black",
        "borderSize": 0,
        "keepRatio": false,
        "custom": {"prompt": imagePrompt}
      });

      newPage.addElement({
        "type": "text",
        "name": "title",
        "opacity": 1,
        "visible": true,
        "selectable": true,
        "removable": true,
        "alwaysOnTop": false,
        "showInExport": true,
        "x": 70.07519229306135,
        "y": 114.99003322258643,
        "width": 935,
        "height": 167,
        "rotation": 0,
        "animations": [],
        "blurEnabled": false,
        "blurRadius": 10,
        "brightnessEnabled": false,
        "brightness": 0,
        "sepiaEnabled": false,
        "grayscaleEnabled": false,
        "shadowEnabled": false,
        "shadowBlur": 5,
        "shadowOffsetX": 0,
        "shadowOffsetY": 0,
        "shadowColor": "black",
        "shadowOpacity": 1,
        "draggable": true,
        "resizable": true,
        "contentEditable": true,
        "styleEditable": true,
        "text": title,
        "placeholder": "",
        "fontSize": 138,
        "fontFamily": "Inter",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "textDecoration": "",
        "fill": "rgba(255,255,255,1)",
        "align": "center",
        "verticalAlign": "top",
        "strokeWidth": 0,
        "stroke": "black",
        "lineHeight": 1.2,
        "letterSpacing": 0,
        "backgroundEnabled": false,
        "backgroundColor": "#7ED321",
        "backgroundOpacity": 1,
        "backgroundCornerRadius": 0.5,
        "backgroundPadding": 0.5,
        "custom": {}
      });

      setProgress(((i + 1) / flashcards.length) * 100);
    }

    setLoading(false);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      generate_style_1();
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  return (
    <div style={{ padding: '16px' }}>
      <div className='text-dark mb-3'><strong>Multiple Flashcard Generator</strong></div>
      <hr />
      <FormGroup
        label={
            <span className="font-small ps-1">Tell me about your flashcards</span>
          }
        labelFor="prompt-textarea"
        className="font-small mb-4"
      >
        <TextArea
          id="prompt-textarea"
          fill
          growVertically
          large
          placeholder="Flashcards of Winter Fruits for 4-Year-Old Toddlers."
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className='font-small'
          style={{ borderRadius: '10px' }}
        />
      </FormGroup>

      <FormGroup
        label={<span className="font-small ps-1">Image Style</span>}
        className="font-small mb-4"
      >
        <div className="card-view-container" style={{ display: 'flex', gap: '8px' }}>
          <Button
            className={`d-flex align-items-center font-small card-view ${imageStyle === 'Cartoon' ? 'selected' : ''}`}
            onClick={() => setImageStyle('Cartoon')}
            style={{ gap: '8px' }}
          >
            <img src="https://images.artwand.ai/flashcard-cartoon-style.jpg" alt="Cartoon" style={{ maxWidth: '100%' }} />
            <span className='d-flex align-items-center justify-content-center pt-1'>Cartoon</span>
          </Button>
          <Button
            className={`d-flex align-items-center font-small card-view ${imageStyle === 'Illustration' ? 'selected' : ''}`}
            onClick={() => setImageStyle('Illustration')}
            style={{ gap: '8px' }}
          >
            <img src="https://images.artwand.ai/flashcard-illustration-style.jpg" alt="Illustration" style={{ maxWidth: '100%' }} />
            <span className='d-flex align-items-center justify-content-center pt-1'>Illustration</span>
          </Button>
          <Button
            className={`d-flex align-items-center font-small card-view ${imageStyle === 'Realistic' ? 'selected' : ''}`}
            onClick={() => setImageStyle('Realistic')}
            style={{ gap: '8px' }}
          >
            <img src="https://images.artwand.ai/football-ball.jpg" alt="Realistic" style={{ maxWidth: '100%' }} />
            <span className='d-flex align-items-center justify-content-center pt-1'>Realistic</span>
          </Button>
        </div>
      </FormGroup>

      {loading && (
        <div style={{
          position: 'relative',
          marginBottom: '16px',
          height: '24px',
          border: '1px solid #ccc',
          borderRadius: '12px',
          overflow: 'hidden',
          textAlign: 'center',
          lineHeight: '24px',
          fontSize: '14px',
          color: '#333',
        }}>
          <div style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: '#612dae',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
          }} />
          <span style={{ position: 'relative', zIndex: 1 }}>
            {Math.round((progress / 100) * totalFlashcards)} / {totalFlashcards}
          </span>
        </div>
      )}
       <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
        <Button
          text="Generate"
          className='brand-button-outline'
          onClick={handleGenerate}
          loading={loading}
          disabled={prompt ? false : true}
          style={{ borderRadius: '9999px' }}
        />
        <Button
          text="Help"
          className='btn btn-light-outline font-small'
          onClick={toggleModal}
          style={{ borderRadius: '9999px' }}
        />
      </div>
      <Dialog
        isOpen={isModalOpen}
        onClose={toggleModal}
        title="How to Create Flashcards?"
      >
        <div className="bp3-dialog-body">
          <div style={iframeContainerStyle}>
            <iframe
                title="Flashcard Creation Tutorial"
                src="https://www.loom.com/embed/9dd89ad1b88e47ce8d2c851e0cc51c74?sid=49340396-1c51-4b36-b3d5-1be8aa47ff8b&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                frameBorder="0"
                allowFullScreen
                style={iframeStyle}
            ></iframe>
          </div>
        </div>
      </Dialog>

    </div>
  );
};

export const MultipleGenerateSection = {
  name: 'multiplegenerate',
  Tab: (props) => (
    <SectionTab name="Multiple" {...props}>
      <Icon icon="grid-view" />
    </SectionTab>
  ),
  Panel: MultipleGeneratePanel,
};