// src/Generator.js
import React, { useEffect, useState } from 'react';
import HeaderMenu from './Header';
import './index.css'; // Import the CSS file
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import { createProject, getAccount, getAccountData, getProject, incrementAccountUsage } from './Utils/get';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { GrEdit } from "react-icons/gr";
import 'shepherd.js/dist/css/shepherd.css';
import Shepherd from 'shepherd.js';


const Generator = () => {
  const [prompt, setPrompt] = useState('');
  const [selectedStyle, setSelectedStyle] = useState("Cartoon");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalFlashcards, setTotalFlashcards] = useState(0);
  const [generatedCards, setGeneratedCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [showCardGenerator, setShowCardGenerator] = useState(false);
  const [showCardSave, setShowCardSave] = useState(false);
  const [showCardEdit, setShowCardEdit] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [numberOfCard, setNumberOfCard] = useState(3);
  const [limitNumber, setLimitNumber] = useState(3);
  const [totalGeneration, setTotalGeneration] = useState(0);
  const [accountId, setAccountId] = useState();
  const [showTrialExpired, setShowTrialExpired] = useState(false);

  const { slug } = useParams();

  const startTour = () => {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: {
          enabled: true
        }
      }
    });

    tour.addStep({
      id: 'text-area',
      text: 'This is where you enter the details about your flashcard.',
      attachTo: {
        element: '#prompt-text',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'styles-area',
      text: 'Choose the style for your flashcard image here.',
      attachTo: {
        element: '#style-selection',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'card-number',
      text: 'Select how many flashcard you will get.',
      attachTo: {
        element: '#card-number',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });

    tour.start();
  };

  useEffect(() => {
    if (showCardGenerator) {
      const onboardingKey = "onboarding:generator";
      if (!localStorage.getItem(onboardingKey)) {
        startTour();
        localStorage.setItem(onboardingKey, "true");
      }
    }
  }, [showCardGenerator]);

  useEffect(() => {
    getAccount().then((id) => {
      setAccountId(id);
    });
  }, []);

  useEffect(() => {
    if (accountId) {
      getAccountData(accountId).then((data) => {
        if (data.isSubscribed) {
          setLimitNumber(100);
        }

        if (!data.isSubscribed) {
          if (data.usage > 2) {
            setShowTrialExpired(true);
          }
        }

        setTotalGeneration(data.usage || 0);
      });
    }
  }, [generatedCards, accountId]);

  const openModal = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    saveCards(title);
    closeModal();
  };

  const saveCards = async (title) => {
    const projectId = await createProject({ name: title, generatedCards, coverImage: generatedCards[0].image_url });
    window.location.href = `/generator/${projectId}`;
    setIsLoading(false);
  };

  const handleStyleSelect = (style) => {
    setSelectedStyle(style);
  };

  const resetCards = () => {
    setShowCardSave(false);
    setShowCardGenerator(true);
    setProgress(0);
    setTotalFlashcards(0);
    setGeneratedCards([]);
  };

  const generator = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'generator',
      prompt: prompt,
      limit: numberOfCard,
      selectedStyle: selectedStyle
    });

    const response = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "action": "card-context", prompt, limit: numberOfCard }),
    });
    const cardContext = await response.json();
    const flashcards = cardContext.flashcards;
    setTotalFlashcards(flashcards.length);

    for (let i = 0; i < flashcards.length; i++) {
      const flashcard = flashcards[i];

      const cardGenerator = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "action": "generator", prompt, "type": selectedStyle, data: flashcard }),
      });
      const data = await cardGenerator.json();
      const imageUrl = data.src;
      const title = data.title;
      const imagePrompt = data.imagePrompt

      const cardRenderer = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "action": "card-render", src: imageUrl, title, imagePrompt }),
      });

      const cardFileResponse = await cardRenderer.json();
      cardFileResponse.id = uuidv4();

      await incrementAccountUsage(accountId);
      setGeneratedCards((prevCards) => [...prevCards, cardFileResponse]);

      setProgress(((i + 1) / flashcards.length) * 100);
    }

    setLoading(false);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      generator();
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const openEditor = (cardId) => {
    window.location.href = `/edit/${slug}/${cardId}`;
  };

  const redirectOfferPage = () => {
    window.location.href = '/offer';
  };

  useEffect(() => {
    if (slug) {
      getProject(slug).then((response) => {
        setGeneratedCards(response.generatedCards);
        setTitle(response.name);
        setEditable(true);
        setShowCardEdit(true);
        setIsPageLoading(false);
      });
    } else {
      setShowCardGenerator(true);
      setIsPageLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    if (progress === 100) {
      setShowCardGenerator(false);
      setShowCardSave(true);
    }
  }, [progress]);

  return (
    <div>
      <HeaderMenu />
      <div className="d-flex flex-column align-items-center p-4">
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFlashCardName">
                <Form.Label className='font-small ps-1'>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter a flashcard project name"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); // Prevent form submission if needed
                      handleSave();
                    }
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className='brand-button' onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Create'}
            </Button>
          </Modal.Footer>
        </Modal>

        {isPageLoading && (
          <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
            <div className="text-center">
              <Spinner animation="border" role="status" className="mb-3">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p>Loading flashcards, please wait...</p>
            </div>
          </div>
        )}

        {showCardGenerator && (
          <div className="card p-4 mb-4 shadow-sm">
            <div className="text-box mb-5" id="prompt-text">
              <label className='mb-2 fw-bold text-muted'>1. Describe the content of flashcards</label>
              <textarea
                className="form-control"
                placeholder="Flashcards of Winter Fruits for 4-Year-Old Toddlers."
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
            </div>
            <div className="config-section mb-3" id="style-selection">
              <label htmlFor="style-textbox" className="fw-bold text-muted">2. Style of the image in the flashcards</label>
              <div className="model-grid d-flex justify-content-between mt-2">
                <div
                  className={`model-item border rounded p-2 ${selectedStyle === 'Cartoon' ? 'border-primary' : ''}`}
                  onClick={() => handleStyleSelect('Cartoon')}
                >
                  <img src="https://images.artwand.ai/flashcard-cartoon-style.jpg" alt="Cartoon" className="model-image img-fluid" />
                  <div className={`overlay${selectedStyle === 'Cartoon' ? '-selected' : ''}`}>
                    <span className="overlay-text">Cartoon</span>
                  </div>
                </div>
                <div
                  className={`model-item border rounded p-2 ${selectedStyle === 'Illustration' ? 'border-primary' : ''}`}
                  onClick={() => handleStyleSelect('Illustration')}
                >
                  <img src="https://images.artwand.ai/flashcard-illustration-style.jpg" alt="Illustration" className="model-image img-fluid" />
                  <div className={`overlay${selectedStyle === 'Illustration' ? '-selected' : ''}`}>
                    <span className="overlay-text">Illustration</span>
                  </div>
                </div>
                <div
                  className={`model-item border rounded p-2 ${selectedStyle === 'Realistic' ? 'border-primary' : ''}`}
                  onClick={() => handleStyleSelect('Realistic')}
                >
                  <img src="https://images.artwand.ai/football-ball.jpg" alt="Realistic" className="model-image img-fluid" />
                  <div className={`overlay${selectedStyle === 'Realistic' ? '-selected' : ''}`}>
                    <span className="overlay-text">Realistic</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-section mb-4" id="card-number">
              <label htmlFor="card-count-slider" className="fw-bold text-muted">3. Number of Cards <span className="badge bg-primary">{numberOfCard}</span></label>
              <input
                type="range"
                className="form-range"
                id="card-count-slider"
                min="1"
                max="100"
                value={numberOfCard}
                onChange={(e) => setNumberOfCard(e.target.value)}
              />
              {numberOfCard > limitNumber && (
                <div className="alert alert-info mt-2">
                  You need to upgrade your account to create more than 3 cards.
                </div>
              )}
            </div>
            {loading ? (
              <div className="progress mb-3" style={{ height: '24px', width: '100%' }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${progress}%`,
                    color: '#fff', // Set text color to white for better visibility
                    backgroundColor: '#007bff', // Ensure the background color contrasts with the text
                    display: 'flex', // Use flexbox to center content
                    justifyContent: 'center', // Center content horizontally
                    alignItems: 'center', // Center content vertically
                    overflow: 'hidden', // Ensure text does not overflow
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                  }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >

                </div>
                <span className='p-1 d'>{Math.round((progress / 100) * totalFlashcards)} / {totalFlashcards}</span>
              </div>
            ) : (
              <button
                className="btn btn-dark w-100 fs-5"
                onClick={(numberOfCard > limitNumber || showTrialExpired) ? redirectOfferPage : handleGenerate}
              >
                {(numberOfCard > limitNumber || showTrialExpired) ? 'Upgrade now' : 'Generate'}
              </button>
            )}
          </div>
        )}

        {showCardSave && (
          <>
            <div className="card">
              <div className="card-content d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '1.5rem' }}>
                  Do you want to save the cards?
                </span>
                <div className='d-flex'>
                  <button
                    className="btn d-flex"
                    style={{ backgroundColor: '#612dae', color: '#fff', marginRight: '8px' }}
                    onClick={openModal}
                  >
                    Save
                  </button>

                  <button
                    className="btn d-flex"
                    style={{ backgroundColor: 'lightgray', color: '#000' }}
                    onClick={resetCards}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <Alert variant='info'>You need to save in order to make any changes to the cards.</Alert>
          </>
        )}

        {showCardEdit && (
          <div className="card">
            <div className="card-content d-flex justify-content-between align-items-center">
              <span style={{ fontSize: '1.5rem' }}>
                {title}
              </span>
              <div className='d-flex'>
                <button
                  className="d-none btn d-flex"
                  style={{ backgroundColor: 'red', color: '#FFF' }}
                  onClick={resetCards}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}


        <div className="generated-cards-grid">
          {generatedCards.map((card, index) => (
            <div key={index} className="generated-card" style={{ position: 'relative' }}>
              <img src={card.image_url} alt="" className="generated-card-image" />
              {editable && (
                <div onClick={() => { openEditor(card.id) }} className="edit-icon" style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '50%',
                  padding: '10px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <GrEdit style={{ color: '#fff', width: '20px', height: '20px' }} />
                </div>
              )}
            </div>
          ))}
        </div>

        {showTrialExpired && !slug && (
          <Alert variant='info'>You have already created <strong>{totalGeneration} flashcards</strong> and reached the free trial limit.</Alert>
        )}

      </div>
    </div>
  );
};

export default Generator;