import React from 'react';
import HeaderMenu from './Header';
import ListingContainer from './CardList';

const Home = () => (
  <div>
    <HeaderMenu />
    <div className="d-flex">
      <div className="flex-grow-1">
        <ListingContainer />
      </div>
    </div>
  </div>
);

export default Home;